import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { StaffRequest, FilterStaffRequest } from "../models/StaffRequest";

const TOKEN_SESSION_STORAGE_KEY = `oidc.user:${process.env.REACT_APP_OIDC_AUTHORITY}:${process.env.REACT_APP_OIDC_CLIENT_ID}`;
const STAFF_GRANTER_API_URL = process.env.REACT_APP_STAFF_GRANTER_API_BASE_URL;

export const staffAPI = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: STAFF_GRANTER_API_URL,
        prepareHeaders: (headers, {getState}) => {
          // @ts-ignore
          headers.set("authorization", `Bearer ${JSON.parse(sessionStorage.getItem(TOKEN_SESSION_STORAGE_KEY)).access_token}`);
          return headers;
        }
      }
    ),
    tagTypes: [],
    endpoints: (builder) => {
      return ({
        searchStaffRequests: builder.query<Array<StaffRequest>, FilterStaffRequest>({
          query: (search) => ({
            url: "/staff_request/search",
            method: 'POST',
            body: search,
          })
        }),
        updateZapierStaffRequests: builder.query<Array<StaffRequest>, void>({
          query: () => ({
            url: "/staff_request/update/request-infos",
            method: 'GET',
          }),
        }),
        putStaffRequest: builder.mutation<void, Partial<StaffRequest>>({
          query: ({ id, status }) => ({
            url: `/staff_request/${id}`,
            method: 'PUT',
            params: {
              new_status: status,
            },
          }),
        }),
        postStaffRequest: builder.mutation<void, StaffRequest>({
          query: (staffRequest) => ({
            url: "/staff_request/",
            method: 'POST',
            body: staffRequest,
          }),
        }),
        triggerAutoGrant: builder.mutation<void, void>({
          query: () => ({
            url: "/staff_request/auto-grant",
            method: 'POST',
          }),
        }),
    })},
});

// Export hooks for usage in functional components
// @ts-ignore
export const {
    useSearchStaffRequestsQuery,
    useUpdateZapierStaffRequestsQuery,
    usePutStaffRequestMutation,
    usePostStaffRequestMutation,
    useTriggerAutoGrantMutation,
} = staffAPI;