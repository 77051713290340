import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { ChangeEvent } from 'react';
import { Fr, De, Gb, It, Es } from "react-flags-select";

export default function BtnGroupLanguages({selectedLanguage, setSelectedLanguage}: any) {

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedLanguage((event.target as HTMLInputElement).value);
  };

  return (
    <FormControl>
      <RadioGroup
        row
        name="row-radio-buttons-group"
        defaultChecked={selectedLanguage}
        value={selectedLanguage}
        onChange={handleChange}
      >
        <FormControlLabel value="" control={<Radio />} label="ALL" />
        <FormControlLabel value="FR" control={<Radio />} label={<Fr style={{ paddingTop: 6,  width: 28 }} />} />
        <FormControlLabel value="DE" control={<Radio />} label={<De style={{ paddingTop: 6,  width: 28 }} />} />
        <FormControlLabel value="UK" control={<Radio />} label={<Gb style={{ paddingTop: 6,  width: 28 }} />} />
        <FormControlLabel value="IT" control={<Radio />} label={<It style={{ paddingTop: 6,  width: 28 }} />} />
        <FormControlLabel value="ES" control={<Radio />} label={<Es style={{ paddingTop: 6,  width: 28 }} />} />
      </RadioGroup>
    </FormControl>
  )
}