import React, {useEffect, useRef } from 'react';
import {useNavigate} from 'react-router-dom';
import {auth} from "../services/auth";


const AuthCallback: React.FC<{
        setUserAuthenticated: (isAuthenticated: boolean) => void;
    }> = ({setUserAuthenticated}) => {
        const navigate = useNavigate();
        const handleCallbackCalled = useRef(false);
        useEffect(() => {
            if (handleCallbackCalled.current) {
              return;
            }
            handleCallbackCalled.current = true;
            const handleAuthentication = async () => {
                try {
                    const currentUser = await auth.getUser();
                    if (currentUser) {
                      const user = await auth.handleSilentCallback();
                    } else {
                      const user = await auth.handleCallback();
                      const path = user.state || `${process.env.REACT_APP_REDIRECT_DOMAIN}/requests/list`
                      setUserAuthenticated(true);
                      navigate(path);
                    }
                } catch (e) {
                    console.error(e);
                    return;
                }
            }
            handleAuthentication();
          }, [navigate, setUserAuthenticated]
        );

        return (
            <div>
                Authenticating..., please wait
            </div>
        );
    }
;

export default AuthCallback;
