import './App.css';
import {BrowserRouter as Router, Route, Routes, Navigate, useLocation} from 'react-router-dom';
import {StaffRequestList} from "./pages/StaffRequestList";
import {StaffRequestCreate} from './pages/StaffRequestCreate';
import {ApiProvider} from "@reduxjs/toolkit/dist/query/react";
import {staffAPI} from "./services/staffAPI";
import {ReactElement, useCallback, useEffect, useState} from "react";
import {CircularProgress} from "@mui/material";
import {auth} from "./services/auth";
import Box from "@mui/material/Box";
import NotAuthenticated from "./pages/NotAuthenticated";
import Header from "./components/Header";
import AuthCallBack from "./pages/AuthCallBack";

let hasLoggedIn = false;
const ProtectedRoute = ({children, isSignedIn}: React.PropsWithChildren<{ isSignedIn: boolean }>): ReactElement => {
    const location = useLocation();
    const handleLogout = useCallback(
      async () => {
        if (isSignedIn) {
          await auth.logout();
          hasLoggedIn = false;
        }
      },
      [isSignedIn]
    )

    const handleLogin = async () => {
      if (hasLoggedIn) {
          return
      } else {
          hasLoggedIn = true
          await auth.login(location.pathname);
      }
    };

    const body = isSignedIn ? children : <NotAuthenticated login={handleLogin}/>;

    return (
      <>
        <Box>
          <Header title="Staff Request Granter" isSignedIn={isSignedIn} handleLogin={handleLogin} handleLogout={handleLogout}/>
          {body}
        </Box>
      </>
    );
}

function App() {
    const [userAuthenticated, setUserAuthenticated] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    useEffect(() => {
        const checkUserAuthenticated = async () => {
            const isAuthenticated = await auth.isAuthenticated();
            setUserAuthenticated(isAuthenticated);
            setIsLoading(false);
        };
        checkUserAuthenticated();
    }, [userAuthenticated, isLoading]);
    if (isLoading) {
        return (
            <div className="App">
                <CircularProgress/>
            </div>
        )
    }

    document.title = "Staff Request Granter"

    return (
      <div className="App">
        <ApiProvider api={staffAPI}>
          <Router basename={process.env.REACT_APP_BASENAME} >
            <Routes>
              <Route
                path={process.env.REACT_APP_OIDC_REDIRECT_PATH}
                element={<AuthCallBack setUserAuthenticated={setUserAuthenticated}/>}
              />
              <Route
                path="/request/list"
                element={
                  <ProtectedRoute isSignedIn={userAuthenticated}>
                    <StaffRequestList/>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/request/create/:id/:status"
                element={
                  <ProtectedRoute isSignedIn={userAuthenticated}>
                    <StaffRequestCreate/>
                  </ProtectedRoute>
                }
              />
              <Route
                path="*"
                element={
                  <ProtectedRoute isSignedIn={userAuthenticated}>
                      <Navigate to="/request/list"/>
                  </ProtectedRoute>
                }
              />
            </Routes>
          </Router>
        </ApiProvider>
      </div>
    );
}

export default App;
