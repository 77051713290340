import {UserManager, UserManagerSettings} from 'oidc-client-ts';


const OIDC_REDIRECT_DOMAIN = process.env.REACT_APP_OIDC_REDIRECT_DOMAIN || "";
const OIDC_CLIENT_ID = process.env.REACT_APP_OIDC_CLIENT_ID || "";
const OIDC_AUTHORITY = process.env.REACT_APP_OIDC_AUTHORITY || "";
const OIDC_CALLBACK_PATH = process.env.REACT_APP_OIDC_REDIRECT_PATH || "";
const OIDC_SCOPE = process.env.REACT_APP_OIDC_SCOPE || "";
const BASE_NAME = process.env.REACT_APP_BASENAME || "";

const config: UserManagerSettings = {
    authority: OIDC_AUTHORITY,
    client_id: OIDC_CLIENT_ID,
    redirect_uri: `${OIDC_REDIRECT_DOMAIN}${BASE_NAME}${OIDC_CALLBACK_PATH}`,
    response_type: 'code',
    scope: OIDC_SCOPE,
    post_logout_redirect_uri: OIDC_REDIRECT_DOMAIN,
    automaticSilentRenew: true,
};

const userManager = new UserManager(config);

export const auth = {
    async login(path?: string) {
        await userManager.signinRedirect({state: path});
    },

    async handleCallback() {
        return await userManager.signinRedirectCallback();
    },

    async handleSilentCallback() {
      console.log("handleSilentCallback")
      return await userManager.signinSilentCallback();
    },

    async logout() {
        await userManager.signoutRedirect();
    },

    async isAuthenticated() {
        const user = await userManager.getUser();
        return user !== null && !user.expired;
    },


    async getUser() {
        const user = await userManager.getUser();
        if (user && !user.expired) {
            return user;
        }
        return null;
    },
};
