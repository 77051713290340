import React from 'react';
import {Button} from "@mui/material";

const NotAuthenticated: React.FC<{ login: () => Promise<void> }> = ({login}) => {


    return (
        <div>
            <h2>You are not logged in.</h2>
            <Button onClick={login} variant="contained"> Sign In</Button>
        </div>
    );
};

export default NotAuthenticated;
