export declare interface PageProps {
    children?: React.ReactNode; // best, accepts everything React can render
    childrenElement?: JSX.Element; // A single React element
    style?: React.CSSProperties; // to pass through style props
    title?: string;
}

function Page({children }: PageProps) {
  return (
    <>
      <div>
        {children}
      </div>
    </>
  )
}

export default Page