import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog({message, staffRequest, open, handleCancel, handleConfirm}: any) {

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirmation"}
        </DialogTitle>
        <DialogContent>
          { staffRequest.id ? 
              <DialogContentText>
                Target User: {staffRequest?.requesting_user.name} <br/>
                Customer ID: {staffRequest?.request.id} <br/>
                Customer Name: {staffRequest?.request.name} <br/>
                Duration: {staffRequest?.duration} <br/>
              </DialogContentText>
              : null
          }
            <DialogContentText sx={{ mt: 2 }} id="alert-dialog-description">
              { message }
            </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}