import Page from "../components/Page";
import { useState, useEffect, useRef } from "react";
import DatatableStaffRequests from "../components/DatatableStaffRequests";
import Box from "@mui/material/Box";
import { useSearchStaffRequestsQuery, useUpdateZapierStaffRequestsQuery, useTriggerAutoGrantMutation } from "../services/staffAPI";
import { StaffRequest, RequestStatus } from "../models/StaffRequest";
import BtnGroupLanguages from "../components/BtnGroupLanguages";

const websocketUrl = process.env.REACT_APP_STAFF_GRANTER_WS_URL as string;

export const StaffRequestList = () => {
  const [staffRequests, setStaffRequests] = useState([] as StaffRequest[]);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const ws = useRef<WebSocket>();

  // Refs for managing batches and timeouts
  const messageBatch = useRef<StaffRequest[]>([]);
  const processingTimeout = useRef<NodeJS.Timeout | null>(null);
  const batchDelay = 2000; // Delay in ms to process batches

  const updateZapierStaffRequests = useUpdateZapierStaffRequestsQuery();
  const [triggerAutoGrantStaffRequests] = useTriggerAutoGrantMutation();

  const staffRequestsSearchQuery = useSearchStaffRequestsQuery(
    { status: RequestStatus.PENDING, country: selectedLanguage, since: 3 },
    { refetchOnMountOrArgChange: true }
  );

  // Function to handle incoming WebSocket messages
  const handleWebSocketMessage = (message: string) => {
    const updatedStaffRequest = JSON.parse(message);

    // Add the new message to the batch
    messageBatch.current.push(updatedStaffRequest);

    // Set up a timeout to process the batch, if not already set
    if (!processingTimeout.current) {
      processingTimeout.current = setTimeout(() => {
        processBatch();
      }, batchDelay);
    }
  
    if (updatedStaffRequest.request_type === "AUTO" && updatedStaffRequest.status === RequestStatus.PENDING) {
      const randomDelay = Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000;
      setTimeout(async () => {
        console.log('Triggering auto grant');
        await triggerAutoGrantStaffRequests();
      }, randomDelay);
    }
  
    // Update staff requests in local state
    setStaffRequests((prevStaffRequests) => {
      if (prevStaffRequests.find((sr) => sr.id === updatedStaffRequest.id)) {
        return prevStaffRequests.map((sr) =>
          sr.id === updatedStaffRequest.id ? updatedStaffRequest : sr
        );
      } else {
        return [...prevStaffRequests, updatedStaffRequest];
      }
    });
    
  };

  // Function to process the batch of WebSocket messages
  const processBatch = async () => {
    console.log('Processing batch of WebSocket messages');
    const batch = messageBatch.current;
    messageBatch.current = []; // Clear the batch

    // Track whether we need to refetch Zapier staff requests
    let needsRefetch = false;

    batch.forEach((msg: any) => {
      if (msg.request.name === "") {
        // If any message has an empty request name, trigger refetch
        needsRefetch = true;
      }
    });

    // Refetch Zapier staff requests if necessary
    if (needsRefetch) {
      console.log('Refetching Zapier staff requests');
      updateZapierStaffRequests.refetch();
    }

    // Clear the processing timeout
    processingTimeout.current = null;
  };

  // Effect to handle search query success and refetch if necessary
  useEffect(() => {
    if (staffRequestsSearchQuery.isSuccess) {
      setStaffRequests(staffRequestsSearchQuery.data);
      console.log('Fetched data from query', staffRequestsSearchQuery.data);
      triggerAutoGrantStaffRequests();
    }
  }, [staffRequestsSearchQuery]);

  // WebSocket connection setup and teardown
  useEffect(() => {
    const reconnectInterval = 5000; // Reconnect every 5 seconds
  
    const connectWebSocket = () => {
      if (ws?.current && ws?.current.readyState === WebSocket.OPEN) {
        return;
      }
      ws.current = new WebSocket(websocketUrl);

      ws.current.onopen = () => {
        console.log("WebSocket opened");
      };
  
      ws.current.onmessage = (event) => {
        console.log("Received WebSocket message", event.data);
        handleWebSocketMessage(event.data);
      };
  
      ws.current.onclose = (event) => {
        console.log(`WebSocket closed. Reconnecting in ${reconnectInterval}ms...`);
        setTimeout(() => {
          connectWebSocket();
        }, reconnectInterval);
      };
  
      ws.current.onerror = (error) => {
        console.log("WebSocket error:", error);
      };
    };
  
    connectWebSocket();

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);

  return (
    <Page title='Staff Request Granter'>
      <Box
        sx={{
          flexGrow: 1,
          padding: '20px',
          maxWidth: '1760px',
          margin: 'auto',
          marginTop: '20px',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <BtnGroupLanguages
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
          />
        </Box>
        <DatatableStaffRequests
          staffRequests={staffRequests}
          setStaffRequests={setStaffRequests}
        />
      </Box>
    </Page>
  );
};