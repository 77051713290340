import Page from "../components/Page";
// import { useState } from "react";
import { useParams } from 'react-router-dom';
// import { StaffRequest } from "../models/StaffRequest";

import Box from "@mui/material/Box";
export const StaffRequestCreate = () => {

  // const [ newStaffRequest, setNewStaffRequest ] = useState({} as StaffRequest);
  
  const params = useParams();

  return (
    <Page
      title='Staff Request Granter'
    >
      <Box sx={{
        flexGrow: 1,
        padding: '20px',
        maxWidth: '1460px',
        margin: 'auto',
        marginTop: '20px'
      }}>

        {JSON.stringify(params)}
        
      </Box>
    </Page>
  )
}