import { Fr, De, Gb, It, Es } from "react-flags-select";


export function convertDuration(duration: string) {
  switch (duration) {
    case 'OneHour':
      return '1 hour';
    case 'TwentyFourHours':
      return '1 day';
    case 'FortyEightHours':
      return '2 days';
    case 'OneWeek':
      return '1 week';
    default:
      return duration
  }
}

export const countryFlagIconSelector = (country: string) => {
  switch(country) {
    case 'FR':
    case 'France':
      return <Fr />;
    case 'DE':
    case 'Germany':
      return <De />;
    case 'UK':
    case 'United Kingdom':
      return <Gb />;
    case 'IT':
    case 'Italy':
      return <It />;
    case 'ES':
    case 'Spain':
      return <Es />;
    default:
      return <Fr />;
  }
}

